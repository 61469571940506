program PSitioPAGOCUOTA;

uses
  WEBLib.Forms,
  
UBase in 'UBase.pas'{*.html},
  UPrincipal in 'UPrincipal.pas'{*.html},
  UWebModuloDatos in '..\..\..\UWebModuloDatos.pas';

{$R *.res}

begin
  Application.Initialize;
  Application.MainFormOnTaskbar := True;
  Application.CreateForm(TForm1, Form1);
  Application.CreateForm(TModuloDatos, ModuloDatos);
  Application.Run;
end.
