unit UWebModuloDatos;

interface

uses
  SysUtils, Classes, JS, Web, WEBLib.Modules, WEBLib.json, WEBLib.cds,
  WEBLib.Dialogs, WEBLib.StdCtrls, db, WEBLib.REST, weblib.graphics, WEBLib.ExtCtrls ;
type

  TGraphType = (gtBitmap, gtIcon, gtMetafile, gtJpeg);

  TModuloDatos = class(TDataModule)
     public
          CDTransporte:TClientDataSet;
          DatasourceTransporte:TDataSource;

          Memo: TMemo;
          function GetTipoField(TipoJSON:String):TFieldType;
          procedure GetFieldTypeInfo(Field: TField; var FieldType, JsonTyp: string);
          function FieldsToJSON(AFields: TFields; const ADateAsString: boolean = True): string;

          function CreoField(Dataset: TClientDataSet; Indice:Integer; Tipo:TFieldtype):TField;

          procedure JsonToDataset(Dataset:TClientDataSet; JSon:string);
          function DataSetToJSON(DataSet: TDataSet; ReturnFields: boolean = True; ReturnData: boolean = True; ReturnOK: Boolean = True): string;
          procedure CopioRegistro(Datasetorigen, Datasetdestino: TClientDataSet);
          procedure CopioTabla(TablaOrigen, TablaDestino: TClientDataSet);
//          function CreoDatasetImagen(const wDataset: TDataset): TWebClientDataSet;

          procedure CopioEstructuraDataset(Fuente,Destino:TClientDataset; doAdd:Boolean);


          function LeerVista(CVista: TClientDataset; SServidor, SServidorVista: string; DataSource:TDataSource):boolean;

          FUNCTION EsEmailValido(CONST Value: String): boolean;
          function ContarPalabras(Cadena:String):integer;

          function ExtraigoPalabra(cadena: string; posicionpalabra: Integer): string; overload;
          function ExtraigoPalabra(cadena: string; posicionpalabra: Integer; Delimitador:char): string; overload;

          function zipcadena(Cadena:string):string;
          function unzipcadena(Cadena:string):string;

          function StringToBase64(Cadena:string):string;
          function Base64toString(Cadena:string):string;

          function PackZip(Cadena:string) : string;
          function UnPackZip(Cadena:string) : string;

          function BlobtoBase64(Blob: TBlobField): string;
          procedure Base64toBlob(Base64: string; Blob: TBlobField);


          function Base64ToArrayBuffer(str: string): TJSArrayBuffer;
          function ArrayBuffertoBase64(Blob:TJSArrayBuffer):string;

          function StringtoArraybuffer(Cadena:string):TJSArrayBuffer;
          function ArrayBufferToString(Blob:TJsArrayBuffer):string;



          function GetClienteFromRequest(Cliente:TClientDataset; StringEntrada : string) : boolean;

          function PackString(Cadena:string) : string;
          function UnPackString(Cadena:string) : string;


          function StringtoJSON(Cadena:String):String;
          function StringtoClientDataSet(Cadena:String):TClientDataset;

          function RellenoString(cadena: string; longitudtotal: Integer; caracterrelleno: char; rellenoizquierdo: boolean): string;

  protected procedure LoadDFMValues; override; end;


 const
  JSON_NULL    = 'null';
  JSON_STRING  = 'string';
  JSON_BOOLEAN = 'boolean';
  JSON_DATE    = 'date';
  JSON_FLOAT   = 'float';
  JSON_INT     = 'int';
  JSON_LARGEINT= 'largeint';
  JSON_TIME    = 'time';
  JSON_DATETIME    = 'datetime';
  JSON_BLOB    = 'blob';

  cstFieldType    = 'FieldType'; //< label of json tag FieldType.
  cstFieldName    = 'FieldName'; //< label of json tag FieldName.
  cstDisplayLabel = 'DisplayLabel'; //< label of json tag Displaylabel.
  cstFieldSize    = 'FieldSize'; //< label of json tag FieldSize.
  cstJsonType     = 'JsonType'; //< label of json tag JsonType.
  cstRequired     = 'Required'; //< label of json tag Required.
  cstFieldIndex   = 'FieldIndex'; //< label of json tag FieldIndex.

var
  cstCols: string = 'Fields'; //< label of json tag Fields.
  cstData: string = 'Data'; //< label of json tag Data.


var
  ModuloDatos: TModuloDatos;


implementation

{%CLASSGROUP 'Vcl.Controls.TControl'}

{$R *.dfm}



function TModuloDatos.GetClienteFromRequest(Cliente: TClientDataset;
  StringEntrada: string): boolean;
var
  SDatos:string;
  Cabecera:string;
  CadUnzip:String;

begin
       result:=False;
       Cabecera:=Copy(StringEntrada,1,10);
       SDatos:=copy(StringEntrada,6,length(StringEntrada)-5);
       if Cabecera='{"Fields":' then
        begin
             SDatos:=StringEntrada;
             if Cliente.Active then Cliente.EmptyDataSet;
              try
                  JSONToDataset(Cliente,SDatos);
                  Result:=True;
              finally

              end;
        end
        else
        begin
             Cabecera:=Copy(StringEntrada,1,5);
             SDatos:=copy(StringEntrada,6,length(StringEntrada)-5);
             if Cliente.Active then Cliente.EmptyDataSet;

             if cabecera='VER00' then /// Es texto plano
                  begin
                        try
                            JSONToDataset(Cliente,SDatos);
                            Result:=True;
                        finally

                        end;
                  end;
             if cabecera='VER01' then /// Es texto zipiado
                  begin
                        try
                            SDatos:=UnPackZip(StringEntrada);
                            JSONToDataset(Cliente,SDatos);
                            Result:=True;
                        finally

                        end;
                  end;
        end;

end;

procedure TModuloDatos.GetFieldTypeInfo(Field: TField; var FieldType, JsonTyp: string);
begin

   if Field.DataType=ftString then
      begin
        FieldType:='ftstring';
        JsonTyp:='string';
      end
     else
        if (Field.DataType=ftInteger) then
        begin
          FieldType:='ftinteger';
          JsonTyp:='integer';
        end
          else
              if (Field.DataType= ftLargeint) then
              begin
                FieldType:='ftlargeint';
                JsonTyp:='largeint';
              end
              else
                   if (Field.DataType=ftFloat) {or (Field.DataType=ftSingle)}
                   then
                      begin
                          FieldType:='ftfloat';
                          JsonTyp:='float';
                      end
                     else
                         if Field.DataType=ftDateTime then
                            begin
                                FieldType:='ftdatetime';
                                JsonTyp:='datetime';
                            end
                           else
                         if Field.DataType=ftDate then
                            begin
                                FieldType:='ftdate';
                                JsonTyp:='date';
                            end
                           else
                         if Field.DataType=fttime then
                            begin
                                FieldType:='fttime';
                                JsonTyp:='time';
                            end
                            else
                               if Field.DataType=ftboolean then
                                  begin
                                      FieldType:='ftboolean';
                                      JsonTyp:='boolean';
                                  end
                                  else
                                     if Field.DataType=ftBlob then
                                        begin
{                                            FieldType:='ftblob';
                                            JsonTyp:='blob';
}
                                            FieldType:='ftstring';
                                            JsonTyp:='string';

                                        end;


//  GetEnumName(typeinfo(tfieldtype), Ord(Field.DataType));

//  JsonTyp := get(Field.DataType);
end;

{ TDataModule1 }

procedure TModuloDatos.CopioEstructuraDataset(Fuente, Destino: TClientDataset; doAdd: Boolean);
var
    i,p: integer;
    Fld: TField;
    dFld: string;
    Field: TField;
begin
  if Destino.Active then Destino.Active:=False;
  Destino.DisableControls;
  if not doAdd then
    begin
        Destino.FieldDefs.Clear;
        Destino.Fields.Clear;
    end;
//            ShowMessage('CopioEstructura - Fuente tiene : '+inttostr(fuente.fielddefs.count)); ;
  for i:=0 to Fuente.fielddefs.count-1 do
    begin
        try
            Destino.FieldDefs.Add(Fuente.FieldDefs[i].Name,Fuente.FieldDefs[i].DataType,Fuente.FieldDefs[i].Size);

         except

        end;
    end;
//            ShowMessage('CopioEstructura - Destino tiene : '+inttostr(destino.fielddefs.count)); ;

  for i:=0 to Fuente.FieldCount-1 do
    begin
        try
           if Fuente.FieldDefs[i].DataType=ftInteger then Field:=TIntegerField.Create(Destino)
              else
            if Fuente.FieldDefs[i].DataType=ftLargeint then  Field:=TLargeintField.Create(Destino)
              else
              if Fuente.FieldDefs[i].DataType=ftString then  Field:=TStringField.Create(Destino)
                else
                 if Fuente.FieldDefs[i].DataType= ftFloat then Field:=TFloatField.Create(Destino)
                   else
                    if (Fuente.FieldDefs[i].DataType=ftDateTime)  then Field:=TDateTimeField.Create(Destino)
                      else
                        if (Fuente.FieldDefs[i].DataType=ftDate)  then  Field:=TDateField.Create(Destino)
                          else
                           if (Fuente.FieldDefs[i].DataType=fttime)  then  Field:=TTimeField.Create(Destino)
                            else
                              if Fuente.FieldDefs[i].DataType=ftBoolean then Field:=TBooleanField.Create(Destino)
                                else
{                                  if (Fuente.FieldDefs[i].DataType=ftblob)  then  Field:=TBlobField.Create(Destino)
                                  else
}
                                  if (Fuente.FieldDefs[i].DataType=ftblob)  then  Field:=TStringField.Create(Destino)
                                  else
            if Field<>nil then
              begin
                    Field.FieldName:=Fuente.FieldDefs[i].Name;
                    Field.Size:=Fuente.FieldDefs[i].Size;
                    Field.Required:=Fuente.FieldDefs[i].Required;
                    Destino.Fields.Add(Field);
              end;


         except

        end;
    end;
  Destino.EnableControls;

end;

procedure TModuloDatos.CopioRegistro(Datasetorigen, Datasetdestino: TClientDataSet);
var
  ar: Integer;
  Stream:TMemoryStream;
begin
  if (Datasetorigen <> nil) and (Datasetdestino <> nil) then
  begin
    //    MessageDlg('Antes de Append',mtInformation,[mbok]);
        Datasetdestino.Append;
    //    MessageDlg('Despues de Append',mtInformation,[mbok]);
        for ar := 0 to Datasetorigen.FieldDefs.Count- 1 do
        begin
              if Datasetdestino.Fields.FindField(Datasetorigen.FieldDefs[ar].Name) <> nil then
                    begin
{                         if Datasetdestino.FieldByName(Datasetorigen.FieldDefs[ar].Name).DataType=ftBlob then
                              begin
                                      Stream:=TMemoryStream.Create;
                                      try
                                          TBlobField(Datasetdestino.FieldByName(Datasetorigen.Fields[ar].FieldName)).SaveToStream(Stream);
                                          Stream.Seek(0,0);
                                          TBlobField(Datasetdestino.FieldByName(Datasetorigen.Fields[ar].FieldName)).LoadFromStream(Stream);
                                      finally
                                           Stream.Free;
                                      end;
                              end
                              else}
                              begin
                //                    MessageDlg('Copiando dato '+Datasetorigen.Fields[ar].FieldName,mtInformation,[mbok]);
                                    Datasetdestino.FieldByName(Datasetorigen.Fields[ar].FieldName).Value := Datasetorigen.FieldByName(Datasetorigen.Fields[ar].FieldName).Value;
                              end;

                    end
                    else
                      MessageDlg('El Field '+Datasetorigen.FieldDefs[ar].Name+' en el destino es nil ',mtInformation,[mbok]);
        end;
        Datasetdestino.post;
  end;
end;

procedure TModuloDatos.CopioTabla(TablaOrigen, TablaDestino: TClientDataSet);
var
  ar: Integer;
//  Link:TTablalink;
begin
    if tablaorigen=nil then  ShowMessage('CopioTabla - Tablaorigen es nil '); ;
    if tabladestino=nil then  ShowMessage('CopioTabla - Tabladestino es nil '); ;
  if (TablaOrigen = nil) or (TablaDestino = nil) then
    begin
      Exit;
    end;
  try
    if not tablaorigen.active then ShowMessage('CopioTabla - Tablaorigen no esta activa'); ;

    if not TablaDestino.active then
        begin
            TablaDestino.Active:=true;
        end;

    if tablaorigen.recordcount=0 then  ShowMessage('CopioTabla - Tablaorigen no tiene registros'); ;
    if (TablaOrigen.State<>dsedit) and (TablaOrigen.State<>dsInsert) and (TablaOrigen.RecordCount>1) then TablaOrigen.First;
    TablaOrigen.DisableControls;
    TablaDestino.DisableControls;

      for ar := 1 to TablaOrigen.RecordCount do
      begin
//        ShowMessage('Copiando  - Tablaorigen no esta activa'); ;
        CopioRegistro(TablaOrigen, TablaDestino);
        if (TablaOrigen.State<>dsedit) and (TablaOrigen.State<>dsInsert) and (TablaOrigen.RecordCount>1) then TablaOrigen.Next;
  //      TablaOrigen.Next;
      end;
    TablaOrigen.EnableControls;
    TablaDestino.EnableControls;

  finally
  end;

end;


function TModuloDatos.FieldsToJSON(AFields: TFields; const ADateAsString: boolean = True): string;
var
  I: integer;
  VField: TField;
  VJsonTyp,VFieldType, VFieldName: String;
  VJSON: TJSONObject;
  Par:TJSONPair;
  Valor:TJSONValue;
  B64:string;
  Decimal:string;
  FechaString:String;



begin
  try
    Decimal:=FormatSettings.DecimalSeparator;
    FormatSettings.DecimalSeparator:='.';

    VJSON := TJSONOBject.Create;

    Par:=TJSONPair.Create;


    for I := 0 to Pred(AFields.Count) do
    begin
      VField := AFields[I];
      VFieldName := VField.FieldName;
      GetFieldTypeInfo(VField,VJsonTyp , VFieldType);

      if VField.DataType=ftBlob then
         begin
//              B64:=StringToBase64(VField.AsString);
              B64:=BlobtoBase64(TBlobField(VField));
              VJSON.AddPair(VFieldName, B64);
         end
      else
          begin
              if (VFieldName='') or (copy(VFieldName,1,1)='_') then VFieldName:=Format('Field%3.3d', [i]);

              if (VFieldType = JSON_NULL) or VField.IsNull then
              begin
//                MessageDlg(Vfield.name+' es: '+vfield.asstring,mtInformation,[mbok]);
                VJSON.AddPair(VFieldName,'0');
                Continue;
              end;
              if VFieldType = JSON_STRING then
                VJSON.AddPair(VFieldName, VField.AsString)
              else
                  if VFieldType = JSON_BOOLEAN then
                    VJSON.AddPair(VFieldName, BoolToStr(VField.AsBoolean))
                      else
                      if (VFieldType = JSON_DATETIME)  then
                      begin
                        if ADateAsString then
                          begin
                                VJSON.AddPair(VFieldName, VField.AsString)
                          end
                        else
                          VJSON.AddPair(VFieldName,FormatFloat('0.00',VField.AsFloat));
                      end
                          else
                      if (VFieldType = JSON_DATE)  then
                      begin
                        if ADateAsString then
                          begin
                                VJSON.AddPair(VFieldName, FormatDateTime ('dd/mm/yyyy', VField.AsDateTime));
//                                ShowMessage(VField.Name+' = '+FormatDateTime ('dd/mm/yyyy', VField.AsDateTime));
                          end
                        else
                          VJSON.AddPair(VFieldName,FormatFloat('0.00',VField.AsFloat));
                      end
                          else
                      if (VFieldType = JSON_TIME)  then
                      begin
                        if ADateAsString then
                          begin
                                VJSON.AddPair(VFieldName, FormatDateTime('hh:nn', VField.AsDateTime))
                          end
                        else
                          VJSON.AddPair(VFieldName,FormatFloat('0.00',VField.AsFloat));
                      end
                          else
                          if (VFieldType = JSON_FLOAT) and (VField.Size > 0) then
                            VJSON.AddPair(VFieldName,FormatFloat('0.00',VField.AsFloat))
                          else
                              if (VFieldType = JSON_FLOAT) and (VField.Size = 0) then
                                VJSON.AddPair(VFieldName, VField.AsString)
                              else
                                  if VFieldType = JSON_INT then
                                    VJSON.AddPair(VFieldName, VField.AsString)
                                  else
                                        VJSON.AddPair(VFieldName, VField.AsString);
          end;
    end;

    Result := VJSON.ToJSON;
  finally
    VJSON.Free;
    Par.Free;
    FormatSettings.DecimalSeparator:=decimal;
  end;
end;


function  TModulodatos.DataSetToJSON(DataSet: TDataSet; ReturnFields: boolean = True; ReturnData: boolean = True; ReturnOK: Boolean = True): string;
var
  i: integer;
  sFields, sData, FieldType, JsonTyp: string;
  List: TStringList;
  Requerido, Tamano, sFieldName,sDisplayLabel: string;

begin
//  ShowMessage('Cantidad de campos: ');
//  ShowMessage('Cantidad de campos: '+IntToStr(DataSet.FieldCount));
  List := TStringList.Create;

  JsonTyp := '';
  FieldType := '';
  sData := '';
  sFields := '';

  try
    List.Sorted := True;



    if ReturnFields then
        begin
              sFields := '"' + cstCols + '":[';

              for i := 0 to DataSet.FieldCount - 1 do
              begin
                GetFieldTypeInfo(DataSet.Fields[i], FieldType, JsonTyp);

                sFieldName:=Dataset.Fields[i].FieldName;
                sDisplayLabel:=Dataset.Fields[i].DisplayLabel;

                if (trim(sFieldName)='') or (copy(trim(sFieldName),1,1)='_') then
                        begin
                          sFieldName:=Format('Field%3.3d', [i]);
                          sDisplayLabel:=sFieldName;
                        end;

                if (DataSet.Fields[i].DataType=ftInteger) or (DataSet.Fields[i].DataType=ftLargeint) then Tamano:='0' else Tamano:=IntToStr(DataSet.Fields[i].Size);
                if DataSet.Fields[i].Required then Requerido:='"True"' else Requerido:='"False"';

                sFields := sFields + format(
                  '{"%s":"%s","%s":%s,"%s":"%s","%s":%s,"%s":"%s","%s":%s,"%s":"%s"}',
                  [cstJsonType, JsonTyp,
                   cstFieldIndex, IntToStr(DataSet.Fields[i].Index),
                   cstFieldType, FieldType,
                   cstFieldSize, tamano,
                   cstFieldName, sFieldName,
                   cstRequired, Requerido,
                   cstDisplayLabel, sDisplayLabel]);

                if i < (dataset.FieldCount - 1) then sFields := sFields + ',';

                List.Add(DataSet.Fields[i].FieldName + '=' + JsonTyp);
              end;

              sFields := sFields + ']';
        end;

    if ReturnData then
            begin
              DataSet.DisableControls;
              DataSet.First;

              sData := '"' + cstData + '":[';

              while not dataset.EOF do
                    begin
                      sData := sData + FieldsToJSON(Dataset.Fields);

                      dataset.Next;

                      if not dataset.EOF then
                        sData := sData + ',';
                    end;

              sData := sData + ']';
            end;

    if returnFields then      Result := sFields;

    if returndata then
            begin
              if ReturnFields then
                Result := sFields + ', ' + sData
              else
                begin
                  if ReturnOK then  Result := '"Result":"OK",' + sData
                  else Result := sData;

                end;
            end;

    Result := format('{%s}', [Result]);
  finally
    List.Free;
    DataSet.First;
    DataSet.EnableControls;
  end;
end;



function TModuloDatos.GetTipoField(TipoJSon: String): TFieldType;
begin
//       Result:=
       if UpperCase(TipoJson)='FTSTRING' then Result:=ftString
        else
         if (UpperCase(TipoJson)='FTFLOAT') then Result:=ftFloat
         else
           if UpperCase(TipoJson)='FTBOOLEAN' then Result:=ftBoolean
           else
               if (UpperCase(TipoJson)='FTDATE') THEN result:=ftDate
               else
               if  (UpperCase(TipoJson)='FTDATETIME') then result:=ftdatetime
                else
                if (UpperCase(TipoJson)='FTTIME') then Result:=fttime
               else
                 if (UpperCase(TipoJson)='FTINTEGER')
                 then Result:=ftInteger
                 else if (UpperCase(TipoJson)='FTLARGEINT')  then Result:=ftLargeint
                 else if (UpperCase(TipoJson)='FTBLOB')  then Result:=ftblob
                   else
                     if UpperCase(TipoJson)='NULL' then Result:=ftUnknown;

end;

function TModuloDatos.CreoField(Dataset: TClientDataSet; Indice:Integer; Tipo:TFieldtype):TField;
begin
//                                                  ShowMessage('DataSet esta por crear el campo : datetime ' ); ;
      try
          if (Tipo=ftInteger)
           then
            Result:=TIntegerField.Create(DataSet)
            else
              if Tipo=ftLargeint then
                Result:=TLargeintField.Create(DataSet)
                else
                 if (Tipo=ftString)
                  or (Tipo=ftFixedChar)
                  or (Tipo=ftString)
                  then
                    Result:=TStringField.Create(DataSet)
                    else
                    if Tipo=ftFloat then
                       Result:=TFloatField.Create(DataSet)
                        else
                          if (Tipo=ftDateTime)  then
                            begin
                                Result:=TDateTimeField.Create(DataSet)
                            end
                            else
                              if (Tipo=ftDate)     then
                                begin
                                    Result:=TDateField.Create(DataSet)
                                end
                                else
                              if (Tipo=fttime)  then
                                begin
                                    Result:=TtimeField.Create(DataSet)
                                end
                                else
                                  if Tipo=ftBoolean then
                                    Result:=TBooleanField.Create(DataSet)
                                    else
                                      if Tipo=ftblob then
                                      begin
                                                Result:=TStringField.Create(DataSet);
                                      end
                                        else
                                         result:=nil;
{                                    ShowMessage('DataSet NO tiene : tipo '+DataSet.FieldDefs[i].Name ); ;
}
      except

      end;

     if Result<>nil then
        begin
              Result.FieldName:=DataSet.FieldDefs[Indice].Name;
              Result.Size:=DataSet.FieldDefs[Indice].Size;
              Result.Required:=DataSet.FieldDefs[Indice].Required;
              Result.DataSet:=DataSet;
              if Dataset.FindField(DataSet.FieldDefs[Indice].Name)=nil then DataSet.Fields.Add(Result);
        end
        else
        begin
           ShowMessage('DataSet no puede definir el campo '+DataSet.FieldDefs[Indice].Name); ;
        end;

end;
procedure TModuloDatos.JsonToDataset(Dataset: TClientDataSet; JSon: string);
var
  JWSon:TJson;
  JFields, JData, JWArray:TJSONArray;
  JWObject:TJSONObject;
  i, ar:integer;
  Cliente:TClientDataSet;
  JFieldsRegistro:TJSONObject;
  NombreCampo:string;
  TipoCampo:TFieldType;
  Tamano:integer;
  Requerido:boolean;
//  Valor:Variant;
  Field:TField;
  StrFechaDia:String;
  t1: TDateTime;
  ts:TFormatSettings;
  BlobField:TBlobField;
  StringStream:TStringStream;





begin
      if Length(json)>0 then
        begin
              JWSon := TJSON.Create;
              try
                JWObject:= JWSon.Parse(json);

                JData:= TJSONArray(JWObject.GetValue('Data'));
                JFields:= TJSONArray(JWObject.GetValue('Fields'));

                Cliente:=TClientDataSet.Create(Self);

                Cliente.FieldDefs.Clear;
                Cliente.Fields.Clear;

//                if JFields=nil then                           ShowMessage('JFIELDS = NIL ' ); ;

                if (JFields=nil) OR (jfields.count=0) then
                  begin
                    try
                          Cliente.Rows:= TJSArray(TJSJSON.parseobject((Json))) ;
                    except
//                          ShowMessage('Cliente USA ROWS' ); ;
                    end;
                  end
                  else
                     begin
                        if JFields.Count>0 then
                            begin
                                for i := 0 to JFields.Count-1 do
                                  begin

                                      JFieldsRegistro:=TJSONObject(JFields.Items[i]);
                                      NombreCampo:=JFieldsRegistro.GetJSONValue('FieldName');
                                      TipoCampo:=GetTipoField(JFieldsRegistro.GetJSONValue('FieldType'));
                                      Tamano:=StrToInt(JFieldsRegistro.GetJSONValue('FieldSize'));
                                      Requerido:=strtobool (JFieldsRegistro.GetJSONValue('Required'));

                                      Cliente.FieldDefs.Add(NombreCampo,TipoCampo,Tamano,requerido);

                                  end;

                                  for i:=0 to Cliente.FieldDefs.Count-1 do
                                  begin
                                       Field:=CreoField(Cliente, i, Cliente.FieldDefs[i].DataType);
                                  end;
        //                         ShowMessage('Cliente tiene : '+inttostr(cliente.fieldcount)+' definidos como fields - fieldcount '); ;
                            end
                            else
                            begin
                                      ShowMessage('No se encontro Fields'); ;
                            end;
                          Cliente.Active:=True;

                          if Cliente.Active then
                            begin
                                  for i := 0 to JData.Count-1 do
                                    begin
                                        JFieldsRegistro:=TJSONObject(JData.Items[i]);
                                        Cliente.Append;
                                        for ar := 0 to Cliente.FieldCount-1 do
                                          begin
                                              NombreCampo:=Cliente.Fields[ar].FieldName;
                                              IF uppercase(JFieldsRegistro.GetJSONValue(NombreCampo))='NULL' then
                                                Cliente.Fields[ar].Value:=NULL
                                                else

                                              if Cliente.Fields[ar].DataType=ftString then
                                                    Cliente.Fields[ar].Value:=JFieldsRegistro.GetJSONValue(NombreCampo)
                                                 else
                                                  if Cliente.Fields[ar].DataType=ftLargeint then
                                                  begin
                                                        Cliente.Fields[ar].value:=StrToInt64(JFieldsRegistro.GetJSONValue(NombreCampo));
          //                                              MessageDlg(Cliente.Fields[ar].AsString,mtInformation,[mbok]);

                                                  end
                                                     else
                                                  if Cliente.Fields[ar].DataType=ftInteger then
                                                        Cliente.Fields[ar].value:=StrToInt(JFieldsRegistro.GetJSONValue(NombreCampo))
                                                     else
                                                        if Cliente.Fields[ar].DataType=ftFloat then
                                                            Cliente.Fields[ar].value:=StrToFloat(JFieldsRegistro.GetJSONValue(NombreCampo))
                                                            else
                                                                if Cliente.Fields[ar].DataType=ftBoolean then
                                                                    Cliente.Fields[ar].value:=StrToBool(JFieldsRegistro.GetJSONValue(NombreCampo))
                                                                    else
                                                                        if (Cliente.Fields[ar].DataType=ftDateTime) then
                                                                            begin
                                                                              if uppercase(JFieldsRegistro.GetJSONValue(NombreCampo))='NULL' then
                                                                                begin
                                                                                    StrFechaDia:='01/01/1990';
                                                                                    Cliente.Fields[ar].value:= StrToDateTime(StrFechaDia);
                                                                                end
                                                                                else
                                                                                begin
                                                                                        StrFechaDia:= (JFieldsRegistro.GetJSONValue(NombreCampo) );
                                                                                        StrFechaDia:=StringReplace(StrFechaDia,'-','/',[rfReplaceAll]);
                                                                                        StrFechaDia:=StringReplace(StrFechaDia,'\/','/',[rfReplaceAll]);
                                                                                        FormatSettings.ShortDateFormat:='dd/MM/yyyy';
                                                                                        FormatSettings.DateSeparator:='/';
                                                                                        Cliente.Fields[ar].value:= StrToDateTime(StrFechaDia);
                                                                                end;
                                                                            end
                                                                            else
                                                                              if (Cliente.Fields[ar].DataType=ftDate) then
                                                                                  begin
                                                                                    if uppercase(JFieldsRegistro.GetJSONValue(NombreCampo))='NULL' then
                                                                                      begin
                                                                                          StrFechaDia:='01/01/1990';
                                                                                          Cliente.Fields[ar].value:= StrToDateTime(StrFechaDia);
                                                                                      end
                                                                                      else
                                                                                      begin
              {                                                                            StrFechaDia:= (JFieldsRegistro.GetJSONValue(NombreCampo) );
                                                                                          StrFechaDia:=StringReplace(StrFechaDia,'-','/',[rfReplaceAll]);
                                                                                          Cliente.Fields[ar].value:= StrToDateTime(StrFechaDia);
              }
                                                                                              StrFechaDia:= (JFieldsRegistro.GetJSONValue(NombreCampo) );

                                                                                              StrFechaDia:=StringReplace(StrFechaDia,'-','/',[rfReplaceAll]);
                                                                                              StrFechaDia:=StringReplace(StrFechaDia,'\/','/',[rfReplaceAll]);

              //                                                                                ShowMessage( StrFechaDia); ;

                                                                                              FormatSettings.ShortDateFormat:='dd/MM/yyyy';
                                                                                              FormatSettings.DateSeparator:='/';
                                                                                              Cliente.Fields[ar].value:= StrToDate(StrFechaDia);


                                                                                      end;
                                                                                  end
                                                                                  else
                                                                                      if (Cliente.Fields[ar].DataType=ftTime) then
                                                                                          begin
                                                                                            if uppercase(JFieldsRegistro.GetJSONValue(NombreCampo))='NULL' then
                                                                                              begin
                                                                                                  StrFechaDia:='00:00:00';
                                                                                                  FormatSettings.ShortTimeFormat:= 'hh:nn:ss';
                                                                                                  FormatSettings.TimeSeparator:=':';
                                                                                                  Cliente.Fields[ar].value:= StrToTime(StrFechaDia);
                                                                                              end
                                                                                              else
                                                                                              begin
                                                                                                  StrFechaDia:= (JFieldsRegistro.GetJSONValue(NombreCampo) );
                                                                                                  FormatSettings.ShortTimeFormat:= 'hh:nn:ss';
                                                                                                  FormatSettings.TimeSeparator:=':';
                                                                                                  Cliente.Fields[ar].value:= StrToTime(StrFechaDia);
                                                                                              end;
                                                                                          end
                                                                                              else
                                                                                                  begin
                                                                                                          if (Cliente.Fields[ar].DataType=ftblob) then
                                                                                                            begin
                  //                                                                                                ShowMessage( 'ASIGNANDO FOTO'); ;
                  //                                                                                                Cliente.Fields[ar].value:=base64tostring(JFieldsRegistro.GetJSONValue(NombreCampo));

          //                                                                                                        base( JFieldsRegistro.GetJSONValue(NombreCampo),TBlobField(Cliente.Fields[ar]));
                                                    Cliente.Fields[ar].AsString:=JFieldsRegistro.GetJSONValue(NombreCampo);
                                    //                ShowMessage(JFieldsRegistro.GetJSONValue(NombreCampo)); ;

          //                                                                                                        base64toBlob( JFieldsRegistro.GetJSONValue(NombreCampo),TBlobField(Cliente.Fields[ar]));
                                                                                                            end;
                                                                                                  end;
                                          end;
                                        Cliente.Post;
                                    end;
                            end;
          //                i:=JWArray.Count;
          //                ShowMessage('Retrieved items:' +inttostr(i));

                            if cliente.fieldcount>0 then
                              begin
          //                        ShowMessage('Cliente tiene: '+inttostr(cliente.recordcount)+' antes de copioestructura'); ;

                                  CopioEstructuraDataset(Cliente,Dataset,False);
          //                        ShowMessage('Dataset tiene: '+inttostr(dataset.fieldcount)+' campos'); ;
                                  CopioTabla(Cliente,Dataset);
          //                        ShowMessage('Dataset tiene: '+inttostr(dataset.recordcount )+' registros'); ;
          //                        Dataset.Active:=True;
                              end;
                     end;


                finally
                    Cliente.Free;

                end;
        end;
{ jo := js.Parse(AResponse);

    ja  := TJSONArray(jo.GetValue('Records'));

    ShowMessage('Retrieved items:' +inttostr(ja.Count));

    for i := 0 to ja.Count - 1 do
    begin
      jo := ja.Items[i];
      WebListBox1.Items.Add(jo.GetJSONValue('id'));
    end;
  finally
    js.Free;

    end;
}
end;


function TModuloDatos.LeerVista(CVista: TClientDataset; SServidor, SServidorVista: string; DataSource:TDataSource):boolean;
begin
end;


function TModuloDatos.PackString(Cadena: string): string;
begin
      Result:='VER00'+Cadena;
end;

function TModuloDatos.PackZip(Cadena: string): string;
begin
    Result:='VER01'+ZipCadena(Cadena);
end;

function TModuloDatos.RellenoString(cadena: string; longitudtotal: Integer;
  caracterrelleno: char; rellenoizquierdo: boolean): string;
var
  cad: string;
begin
      if length(cadena) >= longitudtotal then
      begin
        Result := cadena;
        Exit;
      end;
      cad := cadena;
      if rellenoizquierdo then
      begin
        repeat
          cad := caracterrelleno + cad;
        until length(cad) >= longitudtotal;
      end
      else
      begin
        repeat
          cad := cad + caracterrelleno;
        until length(cad) >= longitudtotal;
      end;
      Result := cad;
end;

function TModuloDatos.StringtoArraybuffer(Cadena: string): TJSArrayBuffer;
begin
  asm

            var string = btoa(unescape(encodeURIComponent(Cadena))),
                charList = string.split(''),
                uintArray = [];
            for (var i = 0; i < charList.length; i++)
             {
                uintArray.push(charList[i].charCodeAt(0));
            }
            return new Uint8Array(uintArray);

  end;
end;

function TModuloDatos.StringToBase64(Cadena: string): string;
var
    B64:string;
begin
          asm
                var strData = btoa(Cadena);
                B64 = strData;
          end;
    result:=b64;
end;

function TModuloDatos.UnPackString(Cadena: string): string;
var
    Cab:string;
    Datos:string;

begin
    Cab:=Copy(Cadena,1,5);
    Result:='';
    if UpperCase(Cab)='VER00' then
      begin
          Datos:=Cadena;
          Delete(Datos,1,5);
          Result:=Datos;
      end;
end;

function TModuloDatos.UnPackZip(Cadena: string): string;
var
    Cab:string;
    Datos:string;
begin
    Cab:=Copy(Cadena,1,5);

    if UpperCase(Cab)='VER01' then
      begin
          Datos:=Cadena;
          Delete(Datos,1,5);
          Result:=UnZipCadena(Datos);
      end
      else
      begin
          Result:=Cadena;
      end;
end;

function TModuloDatos.unzipcadena(Cadena: string): string;
var
    B64:string;
begin
          asm
                var b64Data =   Cadena;
                // Decode base64 (convert ascii to binary)
                var strData     = atob(b64Data);

                // Convert binary string to character-number array
                var charData    = strData.split('').map(function(x){return x.charCodeAt(0);});

                // Turn number array into byte-array
                var binData     = new Uint8Array(charData);

                // Pako magic
                var data        = pako.inflate(binData);

                // Convert gunzipped byteArray back to ascii string:
                var strData     = String.fromCharCode.apply(null, new Uint16Array(data));
                B64 = strData;
          end;
//    Delete(b64,1,4);
    result:=b64;
end;

function TModuloDatos.zipcadena(Cadena: string): string;
var
    B64:string;
    cabeza:string;
    SNro:string;

begin
{          snro:=inttostr(length(cadena));
          snro:=snro.PadRight(4,' ');
          cabeza:=snro+cadena;
}          asm
                var Cad = Cadena;

//                var binaryString = pako.deflate(JSON.stringify(Cad), { to: 'string' });

                 var binaryString = pako.deflate(Cad, { to: 'string' });
                var strData = btoa(binaryString);

                B64 = strData;
          end;
    result:=b64;
end;

function TModuloDatos.Base64toString(Cadena: string): string;
var
    B64:string;
begin
          asm
                var strData     = atob(Cadena);
                B64 = strData;
          end;
    result:=b64;
end;

FUNCTION TModuloDatos.EsEmailValido(CONST Value: String): boolean;

  FUNCTION CheckAllowed(CONST s: String): boolean;
  VAR i: Integer;
  BEGIN
      Result:= False;
      FOR i:= 1 TO Length(s) DO // illegal char in s -> no valid address
      IF NOT (s[i] IN ['a'..'z','A'..'Z','0'..'9','_','-','.']) THEN Exit;
      Result:= true;
  END;

VAR
  i,len: Integer;
  namePart, serverPart: String;
BEGIN // of IsValidEmail
  Result:= False;
  i:= Pos('@', Value);
  IF (i=0) OR (Pos('..',Value) > 0) THEN Exit;
  namePart:= Copy(Value, 1, i - 1);
  serverPart:= Copy(Value,i+1,Length(Value));
  len:=Length(serverPart);
  // must have dot and at least 3 places from end, 2 places from begin
  IF (len<4) OR
     (Pos('.',serverPart)=0) OR
     (serverPart[1]='.') OR
     (serverPart[len]='.') OR
     (serverPart[len-1]='.') THEN Exit;
  Result:= CheckAllowed(namePart) AND CheckAllowed(serverPart);
END;

function TModuloDatos.ContarPalabras(Cadena:String):integer;
var
  Lista:TStringList;
begin
  Lista:=TStringList.Create;
  try
    lista.DelimitedText := cadena;
    lista.Delimiter := ' ';
    if Lista.Count>0 then result:=Lista.Count else Result:=-1;
  finally
    Lista.Free;
  end;
end;

function TModuloDatos.ExtraigoPalabra(cadena: string; posicionpalabra: Integer): string;
var
  Lista: TStringList;
begin
  Lista := TStringList.Create;
  try
    Lista.DelimitedText := cadena;
    Lista.Delimiter := ' ';
    if posicionpalabra <= lista.Count then
      Result := lista.Strings[posicionpalabra - 1]
    else
      Result := '';
  finally
    Lista.Free;
  end;
end;

function TModuloDatos.ExtraigoPalabra(cadena: string; posicionpalabra: Integer; Delimitador:char): string;
var
  Lista: TStringList;
begin
  Lista := TStringList.Create;
  try
    Lista.DelimitedText := cadena;
    Lista.Delimiter := Delimitador;
    if posicionpalabra <= lista.Count then
      Result := lista.Strings[posicionpalabra - 1]
    else
      Result := '';
  finally
    Lista.Free;
  end;
end;


function TModuloDatos.BlobtoBase64(Blob: TBlobField): string;
{var
  Input: TBytesStream;
  Output: TStringStream;
  Encoding: TBase64Encoding;
}
begin
{  Input := TBytesStream.Create;
  try
    blob.SaveToStream(Input);
    Input.Position := 0;
    Output := TStringStream.Create('', TEncoding.ASCII);
    try
      Encoding := TBase64Encoding.Create(0);
      try
        Encoding.Encode(Input, Output);
        Result := Output.DataString;
      finally
        Encoding.Free;
      end;
    finally
      Output.Free;
    end;
  finally
    Input.Free;
  end;
}

       Result:=StringToBase64(Blob.AsString);
       if Result='' then result:='NULL';

end;

procedure TModuloDatos.Base64toBlob(Base64: string; Blob: TBlobField);
{var
  Input: TStringStream;
  Output: TBytesStream;
  Encoding: TBase64Encoding;
}
begin
{  Input := TStringStream.Create(Base64, TEncoding.ASCII);
  try
    Output := TBytesStream.Create;
    try
      Encoding := TBase64Encoding.Create(0);
      try
        Encoding.Decode(Input, Output);
        Output.Position := 0;
        blob.LoadFromStream(Output);
      finally
        Encoding.Free;
      end;
    finally
      Output.Free;
    end;
  finally
    Input.Free;
  end;
}
   IF Base64='NULL' then Blob.value:='NULL' else Blob.AsString:=Base64toString(Base64);


end;


procedure Mostrarimagen(CampoBLOB: TBlobField; Imagen: TImageControl);
var
  stream: TMemoryStream;
  Jpg: TBitmap;
  GraphType: byte;
begin
  if not Assigned(Imagen) then  Exit;

  Jpg := nil;
  stream := nil;
  stream := TMemoryStream.Create;
  try
      try
//            CampoBLOB.SaveToStream(stream);
            if stream.Size > 0 then
            begin
                  stream.Position := 0;
//                  stream.Read( GraphType, 1);
{                  case GraphType of
                    gtBitmap:  Imagen.Picture.LoadFromStream(stream);
                    gtIcon:    Imagen.Icon.LoadFromStream(stream);
                    gtMetafile: Imagen.Metafile.LoadFromStream(stream);
                    gtJpeg:
                      begin
                        Jpg := TJpegImage.Create;
                        Jpg.LoadFromStream(stream);
                        Imagen.Assign(Jpg);
                        Jpg.Free;
                      end
                    else
                      Imagen.Assign(nil); // Limpiar la imagen
                  end;
}            end
            else
              Imagen.Assign(nil);
      except
        Imagen.Assign(nil);
      end;
  finally
      stream.Free;
  end;
end;

function TModuloDatos.ArrayBufferToString(Blob: TJsArrayBuffer): string;
begin

end;

function TModuloDatos.Base64ToArrayBuffer(str: string): TJSArrayBuffer;
  var
    BufView: TJSUInt8Array;
    BinaryString: string;
    I: Integer;
  begin
    BinaryString := window.atob(str);
    Result := TJSArrayBuffer.new(Length(BinaryString));
    BufView := TJSUInt8Array.new(Result);
    for I := 0 to Length(BinaryString) - 1 do
      BufView[I] := TJSString(BinaryString).charCodeAt(I);
  end;

function TModuloDatos.ArrayBuffertoBase64(Blob:TJSArrayBuffer):string;
  var
    BufView: TJSUInt8Array;
    BinaryString: string;
    I: Integer;
  begin
          asm
              var binary = '';
              var bytes = new Uint8Array( Blob );
              var len = bytes.byteLength;
              for (var i = 0; i < len; i++) {
                  binary += String.fromCharCode( bytes[ i ] );
              }
              return window.btoa( binary );

          end;

  end;


function TModuloDatos.StringtoClientDataSet(Cadena:String):TClientDataset;
begin

end;

function TModuloDatos.StringtoJSON(Cadena: String): String;
var
    i, ar:integer;
    Lista:TStringList;
    NombreCampo:string;
    Valor:string;

//    JSONCliente: TJSONArray;
    JSONCliente: TJSONObject;
    Campo: TJSONObject;
//    Campo: TJSONPair;
    JSonString:STring;
    Cliente:TClientDataset;

    TipoCampo:TFieldType;
    Tamano:integer;
    Requerido:boolean;
  //  Valor:Variant;
    Field:TField;
    Dia:TDate;
    Hora:TTime;
    Fecha:TDateTime;
    Numero:integer;
    NroDecimal:double;




begin

     Lista:=TStringList.Create;
     JSONCliente := TJSONObject.Create;
     Cliente:=TClientDataset.Create(nil);
//     JSONCliente := TJSONArray.Create;
     try
         Lista.Delimiter:='^';
         lista.StrictDelimiter :=true ;
         Lista.DelimitedText:=Cadena;

         if Lista.Count > 0 then
            begin
                  for ar:=0 to Lista.Count-1 do
                      begin
                        NombreCampo:=Copy(Lista[ar],1,Pos('=',Lista[ar]));
                        NombreCampo:=Trim( StringReplace(NombreCampo,'=','',[rfReplaceAll]) );
                        Valor:=copy( Lista[ar],Pos('=',Lista[ar])+1, length(Lista[ar])-Pos('=',Lista[ar]));
                        Campo := TJSONObject.Create;
                        JSONCliente.addpair(NombreCampo, Valor);
                        if Length(valor)>10 then TipoCampo:=ftstring
                          else
                          if TryStrToDate(Valor,Fecha) then TipoCampo:=ftDate
                            else
                             if TryStrToTime(Valor,Fecha ) then TipoCampo:=ftTime
                               else
                                 if TryStrToDateTime(Valor,Fecha ) then TipoCampo:=ftDateTime
                                    else
                                       if TryStrToInt (Valor, Numero ) then TipoCampo:=ftInteger
                                          else
                                             if TryStrToFloat (Valor, NroDecimal ) then TipoCampo:=ftFloat
                                                else
                                                   TipoCampo:=ftString;

                        if TipoCampo=ftString then Tamano:=Length(Valor)+10 else Tamano:=0;
                        Requerido:=False;
                        Cliente.FieldDefs.Add(NombreCampo,TipoCampo,Tamano,requerido);
//                        ShowMessage('Campo: '+NombreCampo+' Valor: '+Valor);
                      end;

                  for i:=0 to Cliente.FieldDefs.Count-1 do
                      begin
                           Field:=CreoField(Cliente, i, Cliente.FieldDefs[i].DataType);
                      end;
                  Cliente.Active:=True;
                  Cliente.Append;


                  //console.log(Cliente.Fields);
                  for ar:=0 to Lista.Count-1 do
                      begin
                        NombreCampo:=Copy(Lista[ar],1,Pos('=',Lista[ar]));
                        NombreCampo:=Trim( StringReplace(NombreCampo,'=','',[rfReplaceAll]) );
                        Valor:=copy( Lista[ar],Pos('=',Lista[ar])+1, length(Lista[ar])-Pos('=',Lista[ar]));


                        case Cliente.FieldByName(NombreCampo).DataType of
                             ftInteger : Cliente.FieldByName(NombreCampo).AsInteger:=StrToInt(Valor);
                             ftFloat : Cliente.FieldByName(NombreCampo).AsFloat:=StrToFloat(Valor);
                             ftDate,ftTime, ftDateTime : Cliente.FieldByName(NombreCampo).AsDateTime:=StrToDateTime(Valor);
                             else   Cliente.FieldByName(NombreCampo).asstring:= valor  ;
                        end;
//                        ShowMessage('Campo: '+NombreCampo+' Valor: '+Valor);

                      end;
                  Cliente.Post;
                  JSonString := JSONCliente.ToString;

                  try
//                      JsonToDataset(Cliente,JSonString);
                      Result :=DataSetToJSON(Cliente);
                  finally
                  end;
            end
            ELSE result:='';

     finally
         Lista.Free;
         JSONCliente.Free;
         Cliente.Free;
     end;

end;

procedure TModuloDatos.LoadDFMValues;
begin
  inherited LoadDFMValues;


  try
    Name := 'ModuloDatos';
    Height := 489;
    Width := 625;
  finally
  end;
end;

end.
