unit UPrincipal;

interface

uses
  SysUtils, Classes, JS, Web, WEBLib.Graphics, WEBLib.Controls,
  WEBLib.Forms, WEBLib.Dialogs, UBase, WEBLib.REST, DB, WEBLib.DB,
  WEBLib.CDS, WEBLib.Imaging.GIFImg, WEBLib.StdCtrls, 
WEBLib.DBCtrls, WEBLib.ExtCtrls;

type
  TForm1 = class(TBase)
    WebPanel1: TPanel;
    WebButton1: TButton;
    WNombre: TEdit;
    WAsunto: TEdit;
    WMensaje: TMemo;
    WMail: TEdit;
    procedure WNombreEnter(Sender: TObject);
    procedure WebButton1Click(Sender: TObject);
    procedure WebFormCreate(Sender: TObject);
  private
    { Private declarations }
  public
    { Public declarations }

    function ValidoDatos:boolean;

  protected procedure LoadDFMValues; override; end;

var
  Form1: TForm1;

implementation

{$R *.dfm}

uses UWebModuloDatos;

function TForm1.ValidoDatos: boolean;
begin
   result:=False;
   if WNombre.text='' then mostrarinfo('Ingrese su nombre')
       else
       if (wmail.text='') or not (ModuloDatos.EsEmailValido(wmail.text)) then mostrarinfo('Ingrese un mail valido')
         else
             if (wasunto.text='') then mostrarinfo('Ingrese el asunto de su consulta')
               else
                   if (WMensaje.text='') then mostrarinfo('¿Y su mensaje?')
                     else
                        result:=True;
end;
procedure TForm1.WebButton1Click(Sender: TObject);
begin

  if validodatos then
    begin
          if cdcontacto.active then cdcontacto.emptydataset else  cdcontacto.open;

          cdcontacto.append;
          cdcontacto.fieldbyname('Nombre').asstring:=wnombre.text;
          cdcontacto.fieldbyname('Email').asstring:=wmail.text;
          cdcontacto.fieldbyname('Asunto').asstring:=wasunto.text;
          cdcontacto.fieldbyname('Mensaje').asstring:=WMensaje.text;
          cdcontacto.post;

          enviarmail;
    end;

end;

procedure TForm1.WebFormCreate(Sender: TObject);
begin
      inherited;
      host:='66.97.38.183:';
      host:='www.sistemaswjc.com.ar:';
      Puerto:='9000';
      HTTP:='https://';

end;

procedure TForm1.WNombreEnter(Sender: TObject);
begin
  inherited;
  if cdcontacto.state<>dsedit then cdcontacto.edit;

end;

procedure TForm1.LoadDFMValues;
begin
  inherited LoadDFMValues;

  WebPanel1 := TPanel.Create('contact');
  WebButton1 := TButton.Create('BotonEnviar');
  WNombre := TEdit.Create('IDNombre');
  WAsunto := TEdit.Create('IDAsunto');
  WMensaje := TMemo.Create('IDMensaje');
  WMail := TEdit.Create('IDEmail');

  MensajeEspera.BeforeLoadDFMValues;
  WebPanel1.BeforeLoadDFMValues;
  WebButton1.BeforeLoadDFMValues;
  WNombre.BeforeLoadDFMValues;
  WAsunto.BeforeLoadDFMValues;
  WMensaje.BeforeLoadDFMValues;
  WMail.BeforeLoadDFMValues;
  try
    Height := 679;
    MensajeEspera.Picture.LoadFromFile('UPrincipal.MensajeEspera.Picture.gif');
    WebPanel1.SetParentComponent(Self);
    WebPanel1.Name := 'WebPanel1';
    WebPanel1.Left := 556;
    WebPanel1.Top := 72;
    WebPanel1.Width := 357;
    WebPanel1.Height := 436;
    WebPanel1.BorderColor := clSilver;
    WebPanel1.BorderStyle := bsSingle;
    WebPanel1.ChildOrder := 5;
    WebButton1.SetParentComponent(WebPanel1);
    WebButton1.Name := 'WebButton1';
    WebButton1.Left := 108;
    WebButton1.Top := 305;
    WebButton1.Width := 151;
    WebButton1.Height := 36;
    WebButton1.Caption := 'Enviar';
    WebButton1.ChildOrder := 4;
    SetEvent(WebButton1, Self, 'OnClick', 'WebButton1Click');
    WNombre.SetParentComponent(WebPanel1);
    WNombre.Name := 'WNombre';
    WNombre.Left := 26;
    WNombre.Top := 44;
    WNombre.Width := 301;
    WNombre.Height := 22;
    WNombre.AutoCompletion := acCCName;
    WNombre.ChildOrder := 5;
    WAsunto.SetParentComponent(WebPanel1);
    WAsunto.Name := 'WAsunto';
    WAsunto.Left := 22;
    WAsunto.Top := 125;
    WAsunto.Width := 301;
    WAsunto.Height := 22;
    WAsunto.ChildOrder := 5;
    WMensaje.SetParentComponent(WebPanel1);
    WMensaje.Name := 'WMensaje';
    WMensaje.Left := 24;
    WMensaje.Top := 162;
    WMensaje.Width := 299;
    WMensaje.Height := 111;
    WMensaje.AutoSize := False;
    WMensaje.SelLength := 0;
    WMensaje.SelStart := 0;
    WMail.SetParentComponent(WebPanel1);
    WMail.Name := 'WMail';
    WMail.Left := 26;
    WMail.Top := 84;
    WMail.Width := 301;
    WMail.Height := 22;
    WMail.AutoCompletion := acEmail;
    WMail.ChildOrder := 5;
  finally
    MensajeEspera.AfterLoadDFMValues;
    WebPanel1.AfterLoadDFMValues;
    WebButton1.AfterLoadDFMValues;
    WNombre.AfterLoadDFMValues;
    WAsunto.AfterLoadDFMValues;
    WMensaje.AfterLoadDFMValues;
    WMail.AfterLoadDFMValues;
  end;
end;

end.