unit UBase;

interface

uses
  SysUtils, Classes, JS, Web, WEBLib.Graphics, WEBLib.Controls,
  WEBLib.Forms, WEBLib.Dialogs, WEBLib.Imaging.GIFImg, WEBLib.REST,
  DB, WEBLib.DB, WEBLib.CDS, WEBLib.ExtCtrls;

type
  TBase = class(TForm)
    CDContacto: TClientDataSet;
    CDContactoNombre: TStringField;
    CDContactoEMail: TStringField;
    CDContactoMensaje: TStringField;
    CDContactoAsunto: TStringField;
    DTContacto: TDataSource;
    ConexionEnviar: THttpRequest;
    Conexion: THttpRequest;
    MensajeEspera: TWaitMessage;
    PanelEfectos: TPanel;
    procedure WebFormCreate(Sender: TObject);
    procedure ConexionError(Sender: TObject; ARequest: TJSXMLHttpRequest;
      Event: TEventListenerEvent; var Handled: Boolean);
    procedure ConexionEnviarResponse(Sender: TObject; AResponse: string);
    procedure ConexionEnviarError(Sender: TObject;
      ARequest: TJSXMLHttpRequestRecord; Event: TJSEventRecord;
      var Handled: Boolean);
  private
    FHost: string;
    FPuerto: string;
    FHTTP: String;
    { Private declarations }
  public
    { Public declarations }
    procedure BootstrapWindowShow; overload;
    procedure BootstrapWindowShow(Proc:TModalResultProc);  overload;
    procedure BootstrapWindowHide;

    property Host:string read FHost write FHost;
    property Puerto:string read FPuerto write FPuerto;
    property HTTP:String read FHTTP write FHTTP;

    procedure MostrarVentanaBoot(IDVentana, Titulo:sTRING; ok,cancel:TModalResultProc);
    procedure MostrarInfo(Mensaje: string);

    procedure EnviarMail;

    procedure ArreglarClientDatasets;

    protected procedure LoadDFMValues; override; end;

var
  Base: TBase;
implementation


uses UWebModulodatos;


procedure TBase.ArreglarClientDatasets;
var
  i:integer;
  Field:TField;
  Dataset:TDataset;
begin
{   for I := 0 to Self.ComponentCount-1 do
      begin
        if Components[i] is TField then
            begin

                Field:=TField(Components[i]);
                  try
                    Field.DataSet.FieldDefs.Add(Field.FieldName,Field.DataType,Field.Size,Field.Required);
                  except

                  end;
            end;
      end;
}end;

procedure TBase.BootstrapWindowHide;
begin
   asm
         $("#formplace").modal("hide");
   end;
end;

procedure TBase.BootstrapWindowShow(Proc: TModalResultProc);
begin
//          $(document).on('show.bs.modal','#formplace', Proc)
   asm
          $("#formplace").on('hidden.bs.modal', Proc);
          $("#formplace").modal("show");
   end;
end;

procedure TBase.EnviarMail;
begin
     if cdcontacto.state=dsedit then CDContacto.Post;
     mensajeespera.show;
     conexionenviar.url:=http+host+puerto+'/mailpg';
     conexionenviar.postdata:=modulodatos.packstring(modulodatos.datasettojson(cdcontacto,true,true,false));
     conexionenviar.execute;
end;

procedure TBase.MostrarInfo(Mensaje: string);
begin
//  ShowMessage(Mensaje);
  asm
        const str1 = ' <h4  <p>';
        alertify.alert('Aviso',str1.concat(Mensaje,'</p></h4>'));
//          const str1 = '<h4 <p>';
//          bootbox.dialog({
//                      title: 'Aviso',
//                      message: str1.concat(Mensaje,'</p></h4>'),
//                      size: 'small',
 //                     buttons: {
 //                         cancel: {
 //                            label: "Aceptar",
 //                             className: 'btn-danger',
 //                             callback: function(){
 //                                           console.log('Custom cancel clicked');
 //                                                 }
 //                               }
 //                             }
 //                         }
 //                   );
  end;

end;

procedure TBase.MostrarVentanaBoot(IDVentana, Titulo: sTRING; ok,
  cancel: TModalResultProc);
begin

          asm
                const str1 = '#';
                bootbox.dialog({

                            message: $(str1.concat(IDVentana)),
                            buttons: {
                                      confirm: {
                                          label: 'Aceptar',
                                          className: 'btn-success',callback: function(){
                console.log('Custom OK clicked');
            }

                                                },
                                      cancel: {
                                          label: 'Cancelar',
                                          className: 'btn-danger',
                                          callback: function(){
                console.log('Custom cancel clicked');
            }
                                              }
}
}
                               );
          end;

end;

procedure TBase.WebFormCreate(Sender: TObject);
begin
        arreglarclientdatasets;
       if UpperCase(name)<>'PRINCIPAL' then
        begin
          ElementClassName:='rounded';
        end;
end;

procedure TBase.BootstrapWindowShow;
begin
   asm
         $("#formplace").modal("show");
   end;
end;

procedure TBase.ConexionEnviarError(Sender: TObject;
  ARequest: TJSXMLHttpRequestRecord; Event: TJSEventRecord;
  var Handled: Boolean);
begin
    mensajeespera.hide;
    mostrarinfo('Hubo problemas para enviar el mail');
    handled:=true;
end;

procedure TBase.ConexionEnviarResponse(Sender: TObject; AResponse: string);
begin
        mensajeespera.hide;
        mostrarinfo('Gracias!!');
end;

procedure TBase.ConexionError(Sender: TObject; ARequest: TJSXMLHttpRequest;
  Event: TEventListenerEvent; var Handled: Boolean);
begin
            MensajeEspera.hide;

{          if Sender = conexiondocumento  then
                  begin
                      fbuscandodocumento:=False;
                      mostrarinfo('Hubo inconvenientes en la conexion');
                  end;
}          if sender= conexionenviar then
            begin
                MostrarInfo('Lamentablemente no se pudo enviar el mail. Intente luego ');
            end;


{          if (sender = conexionactualizarpersona) then
            begin
              MostrarInfo('Lamentablemente no se pudo actualizar la informacion de la persona ');
              ocultopaneles;
            end;

          if (sender = conexionactualizarpersona) then
            begin
              MostrarInfo('Lamentablemente no se pudo actualizar la informacion de la persona ');
              ocultopaneles;
            end;
           if sender = conexionaltapersona then
            begin
              MostrarInfo('Lamentablemente no se pudo dar de alta la persona ');
              ocultopaneles;
            end;

            if sender = conexionentidades then
              begin
                  MostrarInfo('Lamentablemente no se pudo leer desde el servidor ');
                  ocultopaneles;
              end;
}

end;

{$R *.dfm}

procedure TBase.LoadDFMValues;
begin
  inherited LoadDFMValues;

  MensajeEspera := TWaitMessage.Create(Self);
  PanelEfectos := TPanel.Create(Self);
  CDContacto := TClientDataSet.Create(Self);
  CDContactoNombre := TStringField.Create(Self);
  CDContactoEMail := TStringField.Create(Self);
  CDContactoMensaje := TStringField.Create(Self);
  CDContactoAsunto := TStringField.Create(Self);
  DTContacto := TDataSource.Create(Self);
  ConexionEnviar := THttpRequest.Create(Self);
  Conexion := THttpRequest.Create(Self);

  MensajeEspera.BeforeLoadDFMValues;
  PanelEfectos.BeforeLoadDFMValues;
  CDContacto.BeforeLoadDFMValues;
  CDContactoNombre.BeforeLoadDFMValues;
  CDContactoEMail.BeforeLoadDFMValues;
  CDContactoMensaje.BeforeLoadDFMValues;
  CDContactoAsunto.BeforeLoadDFMValues;
  DTContacto.BeforeLoadDFMValues;
  ConexionEnviar.BeforeLoadDFMValues;
  Conexion.BeforeLoadDFMValues;
  try
    Name := 'Base';
    Width := 1203;
    Height := 602;
    Font.Charset := DEFAULT_CHARSET;
    Font.Color := clWindowText;
    Font.Height := -11;
    Font.Name := 'Arial';
    Font.Style := [];
    ParentFont := False;
    SetEvent(Self, 'OnCreate', 'WebFormCreate');
    MensajeEspera.SetParentComponent(Self);
    MensajeEspera.Name := 'MensajeEspera';
    MensajeEspera.Left := 160;
    MensajeEspera.Top := 114;
    MensajeEspera.Width := 24;
    MensajeEspera.Height := 24;
    MensajeEspera.Opacity := 0.200000000000000000;
    MensajeEspera.Picture.LoadFromFile('UBase.MensajeEspera.Picture.gif');
    PanelEfectos.SetParentComponent(Self);
    PanelEfectos.Name := 'PanelEfectos';
    PanelEfectos.Left := 396;
    PanelEfectos.Top := 234;
    PanelEfectos.Width := 150;
    PanelEfectos.Height := 60;
    PanelEfectos.BorderColor := clSilver;
    PanelEfectos.BorderStyle := bsSingle;
    PanelEfectos.Caption := 'PanelEfectos';
    PanelEfectos.ChildOrder := 1;
    PanelEfectos.Visible := False;
    CDContacto.SetParentComponent(Self);
    CDContacto.Name := 'CDContacto';
    CDContacto.Left := 236;
    CDContacto.Top := 67;
    CDContactoNombre.SetParentComponent(CDContacto);
    CDContactoNombre.Name := 'CDContactoNombre';
    CDContactoNombre.FieldName := 'Nombre';
    CDContactoNombre.Size := 50;
    CDContactoEMail.SetParentComponent(CDContacto);
    CDContactoEMail.Name := 'CDContactoEMail';
    CDContactoEMail.FieldName := 'EMail';
    CDContactoEMail.Size := 50;
    CDContactoMensaje.SetParentComponent(CDContacto);
    CDContactoMensaje.Name := 'CDContactoMensaje';
    CDContactoMensaje.FieldName := 'Mensaje';
    CDContactoMensaje.Size := 1000;
    CDContactoAsunto.SetParentComponent(CDContacto);
    CDContactoAsunto.Name := 'CDContactoAsunto';
    CDContactoAsunto.FieldName := 'Asunto';
    CDContactoAsunto.Size := 100;
    DTContacto.SetParentComponent(Self);
    DTContacto.Name := 'DTContacto';
    DTContacto.DataSet := CDContacto;
    DTContacto.Left := 331;
    DTContacto.Top := 67;
    ConexionEnviar.SetParentComponent(Self);
    ConexionEnviar.Name := 'ConexionEnviar';
    ConexionEnviar.Command := httpPOST;
    ConexionEnviar.Headers.BeginUpdate;
    try
      ConexionEnviar.Headers.Clear;
      ConexionEnviar.Headers.Add('Cache-Control=no-cache, no-store, must-revalidate');
      ConexionEnviar.Headers.Add('Content-Type=application/json');
    finally
      ConexionEnviar.Headers.EndUpdate;
    end;
    SetEvent(ConexionEnviar, Self, 'OnError', 'ConexionEnviarError');
    SetEvent(ConexionEnviar, Self, 'OnResponse', 'ConexionEnviarResponse');
    ConexionEnviar.Left := 418;
    ConexionEnviar.Top := 68;
    Conexion.SetParentComponent(Self);
    Conexion.Name := 'Conexion';
    Conexion.Headers.BeginUpdate;
    try
      Conexion.Headers.Clear;
      Conexion.Headers.Add('Cache-Control=no-cache, no-store, must-revalidate');
    finally
      Conexion.Headers.EndUpdate;
    end;
    Conexion.Left := 122;
    Conexion.Top := 280;
  finally
    MensajeEspera.AfterLoadDFMValues;
    PanelEfectos.AfterLoadDFMValues;
    CDContacto.AfterLoadDFMValues;
    CDContactoNombre.AfterLoadDFMValues;
    CDContactoEMail.AfterLoadDFMValues;
    CDContactoMensaje.AfterLoadDFMValues;
    CDContactoAsunto.AfterLoadDFMValues;
    DTContacto.AfterLoadDFMValues;
    ConexionEnviar.AfterLoadDFMValues;
    Conexion.AfterLoadDFMValues;
  end;
end;

end.
